import React from "react";
import styled from "styled-components";
import FactsBox from "../../components/facts-box";
import GatsbyLink from "gatsby-link";
import { Routes } from "../../data/routing";
import { IconChevronBig } from "../../components/icons";
import { theme } from "../../styles/styles";
import { graphql, useStaticQuery } from "gatsby";
import { JobOfferListingInterface } from "../../graphql/jobs";
import { JobOfferTile } from "../../components/job-offer";
import { ApplyJobModal } from "../../components/apply-job";
import { JobOfferDataInterface } from "../../components/apply-job/apply-job-modal";

interface LatestJobOffersDataInterface {
  pimcore: {
    latestJobOffers: JobOfferListingInterface;
  };
}

const LastestJobOffers: React.FC = () => {
  const [currentOffer, setCurrentOffer] = React.useState<JobOfferDataInterface | undefined>();

  const { pimcore } = useStaticQuery<LatestJobOffersDataInterface>(graphql`
    query {
      pimcore {
        ...latestJobOffers
      }
    }
  `);

  const onApply = (offerId: string, jobTitle: string) => setCurrentOffer({ offerId, jobTitle });

  return (
    <>
      {pimcore.latestJobOffers.edges.map((edge, index) => (
        <JobOfferWrapper key={`job_offer_${index}`}>
          <JobOfferTile jobOffer={edge.node} onApply={onApply} size="fixed" />
        </JobOfferWrapper>
      ))}
      <JobOfferWrapper>
        <MoreWrapper to={Routes.jobs}>
          <BgWrapper />
          <HeaderMoreWrapper>
            <FactsBox
              facts={[
                {
                  value: pimcore.latestJobOffers.totalCount.toString(),
                  bottomLabel: "open vacancies",
                },
              ]}
            />
          </HeaderMoreWrapper>
          <ContentMoreWrapper>
            <MoreHeadline>Find out more!</MoreHeadline>
            <IconChevronBig />
          </ContentMoreWrapper>
        </MoreWrapper>
      </JobOfferWrapper>
      <ApplyJobModal offerData={currentOffer} onClose={() => setCurrentOffer(undefined)} />
    </>
  );
};

export default LastestJobOffers;

const MoreHeadline = styled.div.attrs({
  className: `font-light text-4xl tablet:text-5xl text-white pr-2 mt-10`,
})``;

const JobOfferWrapper = styled.div.attrs({
  className: "col-span-3 tablet:h-[25rem]",
})``;

const MoreWrapper = styled(GatsbyLink).attrs({
  className: "p-8 rounded-lg grid content-between relative h-full hover:first:bg-yellow/30",
})`
  min-height: 20rem;
  width: 20rem;

  @media ${theme.mediaQueries.tablet} {
    min-height: 20rem;
    width: auto;
  }
`;
const HeaderMoreWrapper = styled.div.attrs({ className: "z-20 " })``;
const ContentMoreWrapper = styled.div.attrs({
  className: "flex flex-column z-20 ",
})``;
const BgWrapper = styled.div.attrs({
  className: "absolute inset-0 bg-yellow/70 backdrop-blur-sm rounded-lg",
})``;
