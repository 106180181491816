import React from "react";
import styled from "styled-components";
import { IconInterface } from "../../images/icons/Icons";

interface BenefitLargeProps {
  headline: string | React.ReactNode;
  subHeadline: string | React.ReactNode;
  icon: IconInterface;
}

const BenefitLargeElement: React.FC<BenefitLargeProps> = ({ headline, subHeadline, icon }) => {
  return (
    <LargeBenefitWrapper>
      <LargeIcoWrapper>
        <Image src={icon.src} alt={icon.alt} width={icon.width} height={icon.height}/>
      </LargeIcoWrapper>
      <TextWrapper>
        <Headline>{headline}</Headline>
        <SubHeadline>{subHeadline}</SubHeadline>
      </TextWrapper>
    </LargeBenefitWrapper>
  );
};

export default BenefitLargeElement;

const LargeBenefitWrapper = styled.div.attrs({
  className:
    "bg-white/10 backdrop-blur-md py-8 tablet:py-14 px-5 tablet:px-14 flex tablet:flex-col items-center rounded-lg ",
})`
  @supports (-moz-appearance: none) {
    background-color: rgba(75, 75, 75, 0.9);
  }
`;
const LargeIcoWrapper = styled.div.attrs({
  className: "w-auto tablet:mb-10 mr-4 tablet:mr-0",
})``;
const Image = styled.img.attrs({ className: "h-full" })``;
const Headline = styled.h3.attrs({
  className: "text-white font-bold text-benefitMobile tablet:text-paragraphTablet tablet:pb-5",
})``;
const SubHeadline = styled.p.attrs({
  className: "text-gray5 text-lg font-normal",
})``;
const TextWrapper = styled.div.attrs({
  className: "text-start tablet:text-center",
})``;
