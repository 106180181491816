import React from "react";
import styled from "styled-components";
import { createTimeline, gsapClearProps } from "../../utilities/animation-helper";

const ScrollDesktop: React.FC = () => {
  const desktopScrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    gsapClearProps([desktopScrollRef?.current]);
    const tl = createTimeline({ repeat: -1 });
    tl.set(desktopScrollRef.current, { autoAlpha: 0 });
    tl.to(desktopScrollRef.current, { autoAlpha: 1, duration: 0.4 }, "+=.2");
    tl.fromTo(desktopScrollRef.current, { y: "-=.3rem" }, { y: "+=.7rem", duration: 1.25 });
    tl.to(desktopScrollRef.current, { autoAlpha: 0, duration: 0.5 }, "-=.75");

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <DesktopIcoWrapper>
      <DesktopScroll ref={desktopScrollRef} />
    </DesktopIcoWrapper>
  );
};

export default ScrollDesktop;

const DesktopIcoWrapper = styled.div.attrs({
  className:
    "absolute bottom-32 left-1/2 -translate-x-1/2 w-6 h-9 rounded-full border-[.15rem] border-white flex items-center justify-center",
})``;
const DesktopScroll = styled.div.attrs({
  className: "w-0.5 h-2.5 bg-white rounded-full",
})``;
