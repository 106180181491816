import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { ButtonLinkSolid } from "../../components/button";
import { Headline2, Paragraph } from "../../components/typography";
import { Routes } from "../../data/routing";
import { theme } from "../../styles/styles";
import { BlogPostListingInterface } from "../../graphql/blog";
import BlogPostListItem from "../../components/blog-post/blog-post-list-item";
import HorizontalSlider from "../../components/horizontal-slider/horizontal-slider";
import { useMatchMedia } from "../../hooks/use-match-media";
import { HomePageContentInterface } from "../../graphql/home-page";
import { createScrollTriggerTimelineScrubReverse, gsapClearProps } from "../../utilities/animation-helper";

interface RecentBlogPostsSectionProps {
  content: HomePageContentInterface;
}

interface LatestJobOffersDataInterface {
  pimcore: {
    recentPosts: BlogPostListingInterface;
  };
}

interface BlogPostWrapperProps {
  margin?: string;
}

const RecentBlogPostsSection: React.FC<RecentBlogPostsSectionProps> = ({ content }) => {
  const { pimcore } = useStaticQuery<LatestJobOffersDataInterface>(graphql`
    query {
      pimcore {
        ...recentPosts
      }
    }
  `);
  const [isMobile, setIsMobile] = React.useState(false);
  const match = useMatchMedia("(max-width: 768px)");
  const contentWrapper = React.useRef<HTMLDivElement | null>(null);
  const desktopTimeline: gsap.TimelineVars = React.useRef<GSAPTimeline>();

  React.useEffect(() => {
    setIsMobile(match);
  }, [match]);

  React.useEffect(() => {
    gsapClearProps([
      contentWrapper.current?.children[0].children[0].children[0],
      contentWrapper.current?.children[0].children[1],
      contentWrapper.current?.children[0].children[2],
    ]);

    if (isMobile) {
      return;
    }

    desktopTimeline.current = createScrollTriggerTimelineScrubReverse(contentWrapper.current, {
      start: "top bottom",
      end: "bottom top",
    });

    desktopTimeline.current
      ?.add("start")
      .from(contentWrapper.current?.children[0].children[0].children[0], { y: "8rem" }, "start")
      .from(contentWrapper.current?.children[0].children[1], { y: "10rem" }, "start")
      .from(contentWrapper.current?.children[0].children[2], { y: "12rem" }, "start");

    return () => {
      if (!isMobile) {
        desktopTimeline?.current?.kill();
      }
    };
  }, [isMobile]);

  return (
    <BgWrapper>
      <Wrapper ref={contentWrapper}>
        <ContentWrapper>
          <HeadlineWrapper>
            <Headline2>{content.blogSectionTitle}</Headline2>
          </HeadlineWrapper>
          <ParagraphWrapper>
            <Paragraph dangerouslySetInnerHTML={{ __html: content.blogSectionContent }} />
          </ParagraphWrapper>
          <ButtonWrapper>
            <ButtonLinkSolid to={Routes.blog}>Discover our Blog</ButtonLinkSolid>
          </ButtonWrapper>
        </ContentWrapper>
      </Wrapper>
      <PaddingWrapper>
        <HorizontalSlider padding="px-4 tablet:px-20">
          {pimcore.recentPosts.edges.map((post, index) => (
            <BlogPostWrapper key={`blog_post_${index}`} margin={index % 2 ? "top" : undefined}>
              <BlogPostListItem post={post.node} variant="home" />
            </BlogPostWrapper>
          ))}
        </HorizontalSlider>
      </PaddingWrapper>
    </BgWrapper>
  );
};

export default RecentBlogPostsSection;

const BgWrapper = styled.div.attrs({ className: "bg-gray2 w-full scrollspy" })``;
const Wrapper = styled.div.attrs({
  className: "tablet:grid gap-4 px-5 tablet:grid-cols-12",
})``;
const ContentWrapper = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-10 pt-14 tablet:mt-0 h-full",
})``;
const HeadlineWrapper = styled.div.attrs({
  className: "tablet:text-center transition-transform",
})``;
const ParagraphWrapper = styled.div.attrs({
  className: "tablet:text-center mb-10 tablet:px-64",
})``;
const ButtonWrapper = styled.div.attrs({
  className: "tablet:text-center mb-16",
})``;
const PaddingWrapper = styled.div.attrs({
  className: "tablet:mt-20 tablet:pb-16",
})``;
const BlogPostWrapper = styled.div.attrs<BlogPostWrapperProps>(({ margin }) => ({
  className: `w-full ${margin === "top" ? "tablet:mt-10" : "tablet:mb-10"}`,
}))<BlogPostWrapperProps>`
  min-width: 19rem;
  @media ${theme.mediaQueries.tablet} {
    min-width: 26rem;
  }
`;
