import React from "react";
import styled from "styled-components";
import { cleanupScrollAnimations, onScrollAnimationTo } from "../../utilities/animation-helper";
import { rem } from "../../styles/styles";
import { useMatchMedia } from "../../hooks/use-match-media";
import Social from "../social/social";
import { HeroProps } from ".";
import ScrollDesktop from "../scroll-down-element/scroll-desktop";
import ScrollMobile from "../scroll-down-element/scroll-mobile";

interface VideoDataInterface {
  src: any;
  poster?: any;
}

const Hero: React.FC<HeroProps> = ({ video, title, poster, children }) => {
  const vidRef = React.useRef<HTMLVideoElement>(null);
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = React.useState<boolean>();
  const [videoData, setVideoData] = React.useState<VideoDataInterface>({
    src: matchMobile ? video.mobile : video.desktop,
    poster: matchMobile ? poster?.mobile : poster?.desktop,
  });

  React.useEffect(() => {
    setIsMobile(matchMobile);
  }, [matchMobile]);

  React.useEffect(() => {
    const heightVideo = isMobile ? "calc(100vh - 7rem)" : "calc(100vh - 12rem)";
    const animation = onScrollAnimationTo(
      vidRef,
      {
        scale: 0.95,
        height: heightVideo,
        borderRadius: rem(8),
      },
      {
        start: "top top",
        once: true,
      }
    );

    setVideoData({
      src: isMobile ? video.mobile : video.desktop,
      poster: isMobile ? poster?.mobile : poster?.desktop,
    });

    return () => {
      cleanupScrollAnimations([animation]);
    };
  }, [isMobile]);

  return (
    <HeroWrapper>
      <Vid src={videoData.src} ref={vidRef} poster={videoData.poster} controls={false} autoPlay muted loop playsInline />
      <Headline>{title ? title : children}</Headline>
      <SocialWrapper>
        <Social linkedin />
      </SocialWrapper>
      {isMobile && <ScrollMobile />}
      {!isMobile && <ScrollDesktop />}
    </HeroWrapper>
  );
};

export default Hero;

const Vid = styled.video.attrs({
  className: "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full object-cover brightness-75",
})``;

export const Headline = styled.div.attrs({
  className:
    "absolute px-10 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-4xl tablet:text-7xl font-light text-white text-center w-full",
})``;

const HeroWrapper = styled.div.attrs({
  className: "absolute left-0 top-0 w-full h-screen overflow-hidden z-10",
})``;

const SocialWrapper = styled.div.attrs({
  className: "hidden tablet:block tablet:absolute tablet:left-10 tablet:bottom-5",
})``;
