import React from "react";
import styled from "styled-components";
import { ButtonLinkSolid } from "../../components/button";
import { Headline2, Paragraph } from "../../components/typography";
import { Routes } from "../../data/routing";
import HorizontalSlider from "../../components/horizontal-slider/horizontal-slider";
import { useMatchMedia } from "../../hooks/use-match-media";
import LastestJobOffers from "./lastest-job-offers";
import { HomePageContentInterface } from "../../graphql/home-page";
import { PimcoreImage } from "../../components/pimcore-image";
import { createScrollTriggerTimelineScrubReverse, gsapClearProps, gsapSet } from "../../utilities/animation-helper";

interface JobsSectionProps {
  content: HomePageContentInterface;
}

const JobsSection: React.FC<JobsSectionProps> = ({ content }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const match = useMatchMedia("(max-width: 768px)");
  const contentRef = React.useRef<HTMLDivElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const desktopTimeline = React.useRef<GSAPTimeline>();
  const desktopTimelineJobsOffer = React.useRef<GSAPTimeline>();

  React.useEffect(() => {
    setIsMobile(match);
  }, [match]);

  React.useEffect(() => {
    gsapClearProps([
      wrapperRef?.current?.children[2].children[0],
      wrapperRef?.current?.children[2].children[1],
      wrapperRef?.current?.children[2].children[2],
      wrapperRef?.current?.children[2].children[3],
      contentRef.current?.children[0],
      contentRef.current?.children[1],
      contentRef.current?.children[2],
    ]);

    if (isMobile) {
      return;
    }

    const jobOffer1 = wrapperRef?.current?.children[2].children[0];
    const jobOffer2 = wrapperRef?.current?.children[2].children[1];
    const jobOffer3 = wrapperRef?.current?.children[2].children[2];
    const jobOffer4 = wrapperRef?.current?.children[2].children[3];

    desktopTimelineJobsOffer.current = createScrollTriggerTimelineScrubReverse(wrapperRef.current, {
      start: "-50 top",
      end: "+=45%",
    });

    const desktopTargets: Array<gsap.TweenTarget> = [];
    if (jobOffer1) {
      gsapSet([jobOffer1], {
        y: "+=8rem",
      });
      desktopTargets.push(jobOffer1);
    }
    if (jobOffer2) {
      gsapSet([jobOffer2], {
        y: "+=12rem",
      });
      desktopTargets.push(jobOffer2);
    }
    if (jobOffer3) {
      gsapSet([jobOffer3], {
        y: "+=16rem",
      });
      desktopTargets.push(jobOffer3);
    }
    if (jobOffer4) {
      gsapSet([jobOffer4], {
        y: "+=12rem",
      });
      desktopTargets.push(jobOffer4);
    }

    desktopTimelineJobsOffer?.current?.to(desktopTargets, {
      y: 0,
      stagger: 0.05,
    });

    desktopTimeline.current = createScrollTriggerTimelineScrubReverse(wrapperRef.current, {
      start: "-300 top",
      end: "+=60%",
    });

    gsapSet([contentRef.current?.children[0], contentRef.current?.children[1], contentRef.current?.children[2]], {
      y: "+=7rem",
      autoAlpha: 1,
    });

    desktopTimeline?.current?.to(
      [contentRef.current?.children[0], contentRef.current?.children[1], contentRef.current?.children[2]],
      {
        y: "-=8rem",
        stagger: 0.015,
        autoAlpha: 1,
      }
    );

    return () => {
      if (!isMobile) {
        desktopTimeline?.current?.kill();
        desktopTimelineJobsOffer?.current?.kill();
      }
    };
  }, [isMobile]);
  return (
    <Wrapper ref={wrapperRef}>
      <ImageWrapper>
        <PimcoreImage
          image={content.jobsSectionBackground}
          mobileImage={content.jobsSectionBackgroundMobile}
          withAspectRatio
        />
      </ImageWrapper>
      <ContentWrapper ref={contentRef}>
        <HeadlineWrapper>
          <Headline2>{content.jobsSectionTitle}</Headline2>
        </HeadlineWrapper>
        <ParagraphWrapper>
          <Paragraph dangerouslySetInnerHTML={{ __html: content.jobsSectionContent }} />
        </ParagraphWrapper>
        <ButtonWrapper>
          <ButtonLinkSolid to={Routes.jobs}>Discover jobs</ButtonLinkSolid>
        </ButtonWrapper>
      </ContentWrapper>

      {isMobile && (
        <MobileJobsWrapper>
          <HorizontalSlider>
            <LastestJobOffers />
          </HorizontalSlider>
        </MobileJobsWrapper>
      )}
      {!isMobile && (
        <JobsWrapper>
          <LastestJobOffers />
        </JobsWrapper>
      )}
    </Wrapper>
  );
};

export default JobsSection;

const Wrapper = styled.div.attrs({
  className: "tablet:grid tablet:gap-4 tablet:grid-cols-12 tablet:grid-rows-4 relative tablet:mb-36 scrollspy",
})``;
const MobileJobsWrapper = styled.div.attrs({ className: "pt-44" })``;
const ContentWrapper = styled.div.attrs({
  className:
    "tablet:col-start-2 tablet:col-span-5 px-5 tablet:row-start-1 tablet:row-span-2 relative pt-14 tablet:pt-0 tablet:mt-24",
})``;
const ImageWrapper = styled.div.attrs({
  className: "absolute inset-0",
})``;
const HeadlineWrapper = styled.div.attrs({})``;
const ParagraphWrapper = styled.div.attrs({
  className: "tablet:pr-20 mb-8 tablet:mb-10",
})``;
const ButtonWrapper = styled.div.attrs({ className: "tablet:pt-5" })``;

const JobsWrapper = styled.div.attrs({
  className:
    "relative discrete-scrollbar flex tablet:grid grid-flow-col gap-4 px-5 row-start-3 row-span-2 mt-64 pb-8 tablet:grid-cols-12 tablet:mt-16 tablet:col-start-1 tablet:col-span-12 overflow-hidden tablet:overflow-visible",
})``;
