import React from "react";
import styled from "styled-components";
import { createTimeline, gsapClearProps } from "../../utilities/animation-helper";
import ArrowIco from "./arrow-ico";

const ScrollMobile: React.FC = () => {
  const mobileScrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    gsapClearProps([mobileScrollRef.current]);
    const tl = createTimeline({ repeat: -1 });
    tl.set(mobileScrollRef?.current?.children[0] || null, { autoAlpha: 0 });
    tl.to(mobileScrollRef?.current?.children[0] || null, { autoAlpha: 1, duration: 0.75 });
    tl.fromTo(
      mobileScrollRef?.current?.children[0] || null,
      { y: "-=.1rem" },
      { y: "+=1.25rem", duration: 1.5 },
      "-=.35"
    );
    tl.to(mobileScrollRef?.current?.children[0] || null, { autoAlpha: 0, duration: 0.75 }, "-=1");

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <MobileIcoWrapper ref={mobileScrollRef}>
      <IcoWrapper>
        <ArrowIco />
      </IcoWrapper>
    </MobileIcoWrapper>
  );
};

export default ScrollMobile;

const IcoWrapper = styled.div.attrs({ className: "w-1/2 h-1/2" })``;
const MobileIcoWrapper = styled.div.attrs({
  className:
    "w-8 h-8 bg-black/30 absolute bottom-32 left-1/2 -translate-x-1/2 rounded-full border-[.15rem] border-white/30 flex items-center justify-center overflow-hidden",
})``;
