import React from "react";
import styled from "styled-components";
import HomeSectionElement from "./home-section-element";
import { IconX } from "../../components/icons";
import { theme } from "../../styles/styles";
import QuoteElement from "../../components/quote-element/quote-element";
import { useMatchMedia } from "../../hooks/use-match-media";
import { HomePageContentInterface } from "../../graphql/home-page";
import { Routes } from "../../data/routing";
import { PimcoreImage } from "../../components/pimcore-image";
import { createScrollTriggerTimelineScrubReverse, gsapClearProps } from "../../utilities/animation-helper";

interface HomeSectionProps {
  content: HomePageContentInterface;
}

interface FactWrapper {
  padding?: string;
}

interface TitleProps {
  margin?: string;
}

interface ImgProps {
  modifier?: string;
}

const HomeSection: React.FC<HomeSectionProps> = ({ content }) => {
  const sections = content.sections;
  const mediaWrapper1 = React.useRef<HTMLDivElement>(null);
  const mediaWrapper2 = React.useRef<HTMLDivElement>(null);
  const mediaWrapper3 = React.useRef<HTMLDivElement>(null);
  const mediaWrapper4 = React.useRef<HTMLDivElement>(null);
  const isMobile = useMatchMedia("(max-width: 768px)");
  const mobileTl = React.useRef<GSAPTimeline>();
  const mobileTl2 = React.useRef<GSAPTimeline>();
  const mobileTl3 = React.useRef<GSAPTimeline>();
  const mobileTl4 = React.useRef<GSAPTimeline>();
  const desktopTl = React.useRef<GSAPTimeline>();
  const desktopTl2 = React.useRef<GSAPTimeline>();
  const desktopTl3 = React.useRef<GSAPTimeline>();
  const desktopTl4 = React.useRef<GSAPTimeline>();

  React.useEffect(() => {
    const wrappers = [
      mediaWrapper1.current,
      mediaWrapper2.current,
      mediaWrapper3.current,
      mediaWrapper4.current,
      mediaWrapper1.current?.parentNode?.children[0],
      mediaWrapper2.current?.parentNode?.children[0],
      mediaWrapper3.current?.parentNode?.children[0],
      mediaWrapper4.current?.parentNode?.children[0],
      mediaWrapper1?.current?.children[0],
      mediaWrapper1?.current?.children[1],
      mediaWrapper1?.current?.children[2],
      mediaWrapper2?.current?.children[0],
      mediaWrapper2?.current?.children[1],
      mediaWrapper2?.current?.children[2],
      mediaWrapper3?.current?.children[2].children[0].children[1],
      mediaWrapper3?.current?.children[2].children[0].children[0],
      mediaWrapper3?.current?.children[0],
      mediaWrapper3?.current?.children[1],
      mediaWrapper4?.current,
      mediaWrapper4?.current?.children[0],
      mediaWrapper4?.current?.children[1],
      mediaWrapper4?.current?.children[2],
    ];

    gsapClearProps(wrappers);
    if (isMobile) {
      mobileTl.current = createScrollTriggerTimelineScrubReverse(mediaWrapper1.current, {
        start: "top center",
        end: "bottom center",
      });

      mobileTl?.current?.fromTo(mediaWrapper1.current, { x: "-6rem", y: "2rem" }, { x: "7rem", y: "-4rem" });

      mobileTl2.current = createScrollTriggerTimelineScrubReverse(mediaWrapper2.current, {
        start: "top center",
        end: "bottom center",
      });

      mobileTl2?.current?.fromTo(mediaWrapper2.current, { x: "-10rem", y: "3rem" }, { x: "5rem", y: "-5rem" });

      mobileTl3.current = createScrollTriggerTimelineScrubReverse(mediaWrapper3.current, {
        start: "top center",
        end: "bottom center",
      });

      mobileTl3?.current?.fromTo(mediaWrapper3.current, { x: "-7rem", y: "1rem" }, { x: "5rem", y: "-3rem" });

      mobileTl4.current = createScrollTriggerTimelineScrubReverse(mediaWrapper4.current, {
        start: "top center",
        end: "bottom center",
      });
      mobileTl4?.current?.fromTo(mediaWrapper4.current, { x: "-6rem", y: "3rem" }, { x: "4rem", y: "-5rem" });
    } else {
      const homeSection1 = mediaWrapper1.current?.parentNode?.children[0] || null;
      const homeSection2 = mediaWrapper2.current?.parentNode?.children[0] || null;
      const homeSection3 = mediaWrapper3.current?.parentNode?.children[0] || null;
      const homeSection4 = mediaWrapper4.current?.parentNode?.children[0] || null;

      desktopTl.current = createScrollTriggerTimelineScrubReverse(homeSection1, {
        start: "top+=20% center",
        end: "bottom+=40% center",
      });

      desktopTl?.current?.fromTo(
        [mediaWrapper1?.current?.children[0], mediaWrapper1?.current?.children[1], mediaWrapper1?.current?.children[2]],
        { y: "+=40rem" },
        { y: "-=45rem", stagger: 0.1, duration: 2 }
      );

      desktopTl2.current = createScrollTriggerTimelineScrubReverse(homeSection2, {
        start: "top+=15% center",
        end: "bottom+=25% center",
      });

      desktopTl2?.current?.fromTo(
        [mediaWrapper2?.current?.children[0], mediaWrapper2?.current?.children[1], mediaWrapper2?.current?.children[2]],
        { y: "+=35rem" },
        { y: "-=40rem", stagger: 0.1, duration: 3 }
      );

      desktopTl3.current = createScrollTriggerTimelineScrubReverse(homeSection3, {
        start: "top+=30% center",
        end: "bottom+=40% center",
      });

      desktopTl3?.current
        ?.add("start")
        .from(
          [mediaWrapper3?.current?.children[0], mediaWrapper3?.current?.children[1]],
          { y: "+=50rem", stagger: 0.2, duration: 4 },
          "start"
        )
        .from(
          [
            mediaWrapper3?.current?.children[2].children[0].children[1],
            mediaWrapper3?.current?.children[2].children[0].children[0],
          ],
          { y: "+=55rem", stagger: 0.2, duration: 4 },
          "start"
        );

      desktopTl4.current = createScrollTriggerTimelineScrubReverse(homeSection4, {
        start: "top+=30% center",
        end: "bottom+=40% center",
      });

      desktopTl4?.current
        ?.add("start")
        .from(
          [mediaWrapper4?.current],
          {
            y: "35rem",
            duration: 4,
          },
          "start"
        )
        .from(
          [
            mediaWrapper4?.current?.children[0],
            mediaWrapper4?.current?.children[1],
            mediaWrapper4?.current?.children[2],
          ],
          {
            y: "4rem",
            stagger: 0.75,
            duration: 4,
          },
          "start"
        );
    }

    return () => {
      if (isMobile) {
        mobileTl?.current?.kill();
        mobileTl2?.current?.kill();
        mobileTl3?.current?.kill();
        mobileTl4?.current?.kill();
      } else {
        desktopTl?.current?.kill();
        desktopTl2?.current?.kill();
        desktopTl3?.current?.kill();
        desktopTl4?.current?.kill();
      }
    };
  }, [isMobile]);

  return (
    <Wrapper>
      <FirstHomeSection>
        <HomeSectionElement
          background={sections[0].background}
          backgroundMobile={sections[0].backgroundMobile}
          headline={sections[0].title}
          content={sections[0].content}
          buttonLabel="Discover our story"
          buttonTo={Routes.story}
          modifierParagraph="tablet:pr-32"
        >
          <MediaWrapper1 ref={mediaWrapper1}>
            <MediaCell1>
              {sections[0]?.gallery?.[0]?.image && (
                <PimcoreImage image={sections[0].gallery[0].image} modifier="w-full rounded-lg" withAspectRatio />
              )}
            </MediaCell1>
            <MediaCell2>
              {sections[0]?.gallery?.[1]?.image && (
                <PimcoreImage image={sections[0].gallery[1].image} modifier="w-full rounded-lg" withAspectRatio />
              )}
            </MediaCell2>
            <MediaCell3>
              <FactWrapper>
                <IcoWrapper>
                  <IconX />
                </IcoWrapper>
                <ContentFactWrapper>
                  <Title>{sections[0].factTopText}</Title>
                  <Content>{sections[0].factValue}</Content>
                  <Title
                    dangerouslySetInnerHTML={{
                      __html: sections[0].factBottomText.replaceAll("|", "<br />"),
                    }}
                  />
                </ContentFactWrapper>
              </FactWrapper>
            </MediaCell3>
          </MediaWrapper1>
        </HomeSectionElement>
      </FirstHomeSection>
      <SecondHomeSection>
        <HomeSectionElement
          background={sections[1].background}
          backgroundMobile={sections[1].backgroundMobile}
          headline={sections[1].title}
          content={sections[1].content}
          buttonLabel="Discover our work"
          buttonTo={Routes.work}
          align="left"
          modifierParagraph="tablet:pr-36"
        >
          <MediaWrapper2 ref={mediaWrapper2}>
            <MediaCell4>
              <QuoteElement
                message="We call on industry best practice, data and our expertise to define, shape and optimise a smooth customer experience."
                name="Kapil"
                position="Senior Product Manager"
                modifier="hidden tablet:flex bottom-28 left-4 right-auto tablet:left-auto tablet:bottom-80 tablet:right-64"
                place="homepage"
              />
              {sections[1]?.gallery?.[0]?.image && (
                <PimcoreImage image={sections[1].gallery[0].image} modifier="w-full rounded-lg" withAspectRatio />
              )}
            </MediaCell4>
            <MediaCell5>
              {sections[1]?.gallery?.[1]?.image && (
                <PimcoreImage image={sections[1].gallery[1].image} modifier="w-full rounded-lg" withAspectRatio />
              )}
            </MediaCell5>
          </MediaWrapper2>
        </HomeSectionElement>
      </SecondHomeSection>
      <ThirdSectionElement>
        <HomeSectionElement
          background={sections[2].background}
          backgroundMobile={sections[2].backgroundMobile}
          headline={sections[2].title}
          content={sections[2].content}
          buttonLabel="Discover our team"
          buttonTo={Routes.team}
          modifierParagraph="tablet:pr-36"
        >
          <MediaWrapper3 ref={mediaWrapper3}>
            <MediaCell6>
              <QuoteElement
                message="I’ve never worked in a company with more than 40 nationalities, so that’s very impressive."
                name="Geiske"
                position="Program Manager Channel Management"
                modifier="hidden tablet:flex bottom-4 left-4 tablet:left-auto tablet:bottom-60 tablet:right-52"
                place="homepage"
              />
              {sections[2]?.gallery?.[0]?.image && (
                <PimcoreImage image={sections[2].gallery[0].image} modifier="w-full rounded-lg" withAspectRatio />
              )}
            </MediaCell6>
            <MediaCell7>
              {sections[2]?.gallery?.[1]?.image && (
                <PimcoreImage image={sections[2].gallery[1].image} modifier="w-full rounded-lg" withAspectRatio />
              )}
            </MediaCell7>
            <MediaCell8>
              <MediaCell8Wrapper>
                <ImageMediaCell8Wrapper>
                  {sections[2]?.gallery?.[2]?.image && (
                    <PimcoreImage image={sections[2].gallery[2].image} modifier="w-full rounded-lg" withAspectRatio />
                  )}
                </ImageMediaCell8Wrapper>
                <FactMediaCell8Wrapper>
                  <FactWrapper>
                    <IcoWrapper>
                      <IconX />
                    </IcoWrapper>
                    <ContentFactWrapper>
                      <Content>
                        <PrefixElement>{sections[2].factValuePrefix}</PrefixElement>
                        {sections[2].factValue}
                      </Content>
                      <Title margin="ml-6">{sections[2].factBottomText}</Title>
                    </ContentFactWrapper>
                  </FactWrapper>
                </FactMediaCell8Wrapper>
              </MediaCell8Wrapper>
            </MediaCell8>
          </MediaWrapper3>
        </HomeSectionElement>
      </ThirdSectionElement>
      <FourthSectionElement>
        <HomeSectionElement
          background={sections[3].background}
          backgroundMobile={sections[3].backgroundMobile}
          headline={sections[3].title}
          content={sections[3].content}
          buttonLabel="Discover our Community Space"
          buttonTo={Routes.office}
          align="left"
          modifierParagraph="tablet:pr-24"
        >
          <MediaWrapper4 ref={mediaWrapper4}>
            <MediaCell10>
              {sections[3]?.gallery?.[0]?.image && (
                <PimcoreImage image={sections[3].gallery[0].image} modifier="w-full rounded-lg" withAspectRatio />
              )}
            </MediaCell10>
            <MediaCell11>
              {sections[3]?.gallery?.[1]?.image && (
                <PimcoreImage image={sections[3].gallery[1].image} modifier="w-full rounded-lg" withAspectRatio />
              )}
            </MediaCell11>
            <MediaCell12>
              <FactWrapper>
                <IcoWrapper>
                  <IconX />
                </IcoWrapper>
                <ContentFactWrapper>
                  <Content>
                    {sections[3].factValue}
                    <SuffixElement>{sections[3].factValueSuffix}</SuffixElement>
                  </Content>
                  <Title
                    margin="tablet:mt-2 font-bold"
                    dangerouslySetInnerHTML={{
                      __html: sections[3].factBottomText.replaceAll("|", "<br />"),
                    }}
                  />
                  <SubTitle
                    dangerouslySetInnerHTML={{
                      __html: sections[3].factBottomSmallText.replaceAll("|", "<br />"),
                    }}
                  />
                </ContentFactWrapper>
              </FactWrapper>
            </MediaCell12>
          </MediaWrapper4>
        </HomeSectionElement>
      </FourthSectionElement>
    </Wrapper>
  );
};

export default HomeSection;

const Wrapper = styled.div.attrs({ className: "overflow-hidden" })``;
const FirstHomeSection = styled.div.attrs({ className: "scrollspy" })``;
const SecondHomeSection = styled.div.attrs({ className: "scrollspy" })``;
const ThirdSectionElement = styled.div.attrs({ className: "scrollspy" })``;
const FourthSectionElement = styled.div.attrs({ className: "scrollspy" })``;
const Title = styled.p.attrs<TitleProps>(({ margin }) => ({
  className: `text-paragraph text-white font-bold ${margin ? margin : ""}`,
}))<TitleProps>``;
const SubTitle = styled.p.attrs({ className: "text-sm	leading-6 text-white" })``;
const IcoWrapper = styled.div.attrs({
  className: "absolute inset-0 -top-28 -left-36 tablet:-top-36 tablet:-left-44 -translate-y-1/2",
})`
  @media ${theme.mediaQueries.tablet} {
    width: 50vw;
  }
  width: 175vw;
`;
const Content = styled.p.attrs({
  className: "text-subHeadline text-white font-bold flex",
})``;
const ContentFactWrapper = styled.div.attrs({})``;
const MediaCell8Wrapper = styled.div.attrs({ className: "w-full grid grid-cols-8" })`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(auto, 2);
  }
`;
const PrefixElement = styled.span.attrs({
  className: "text-4xl ",
})``;
const SuffixElement = styled.span.attrs({
  className: "text-5xl ml-1",
})``;
const MediaWrapper1 = styled.div.attrs({
  className:
    "-mx-40 -mt-12 tablet:-mt-80 tablet:-mx-6 col-start-1 col-span-6 grid grid-cols-12 gap-4 row-start-4 row-span-4 mt-8 -translate-x-10 tablet:translate-x-0",
})``;
const MediaCell1 = styled.div.attrs({
  className: "col-start-1 col-span-5 row-start-1 row-span-2 mt-24",
})``;
const MediaCell2 = styled.div.attrs({
  className: "col-start-6 col-span-7 row-start-1 row-span-1 tablet:mt-auto",
})``;
const MediaCell3 = styled.div.attrs({
  className: "col-start-6 col-span-6 tablet:col-span-4 row-start-2 row-span-1 ",
})``;
const FactWrapper = styled.div.attrs<FactWrapper>(({ padding }) => ({
  className: `${
    padding ? padding : ""
  } bg-gray14 w-52 h-52 tablet:w-56 tablet:h-56 flex items-center justify-center relative rounded-lg overflow-hidden`,
}))<FactWrapper>``;
const Image = styled.img.attrs<ImgProps>(({ modifier }) => ({
  className: `${modifier ? modifier : "w-full"} rounded-lg`,
}))<ImgProps>``;

const MediaWrapper2 = styled.div.attrs({
  className:
    "-mx-56 -mt-16 tablet:-mt-96 col-start-6 col-span-7 grid grid-cols-14 gap-4 row-start-5 row-span-2 tablet:-mx-8 -translate-x-36 tablet:translate-x-0",
})``;
const MediaCell4 = styled.div.attrs({
  className: "col-start-9 tablet:col-span-6 col-span-7 row-start-1 relative z-10",
})``;
const MediaCell5 = styled.div.attrs({
  className: "col-start-3 col-span-6 row-start-1 mt-24",
})``;
const MediaWrapper3 = styled.div.attrs({
  className:
    "grid -mx-28 -mt-12 tablet:-mt-96 grid-cols-10 gap-4 col-start-1 col-span-5 row-start-3 row-span-4 tablet:-mx-8 -translate-x-28 tablet:-translate-x-12",
})``;
const MediaCell6 = styled.div.attrs({
  className: "col-start-6 col-span-5 row-start-1 relative tablet:mt-auto z-10",
})``;
const MediaCell7 = styled.div.attrs({
  className: "col-start-1 col-span-5 row-start-1 w-11/12 mt-auto ml-auto",
})``;
const MediaCell8 = styled.div.attrs({
  className: "col-start-1 col-span-10 row-start-2 flex",
})``;
const MediaWrapper4 = styled.div.attrs({
  className:
    "grid -mt-16 tablet:-mt-24 -mx-40 grid-cols-12 tablet:grid-cols-10 gap-4 col-start-7 col-span-6 row-start-2 row-span-5 tablet:-mr-8 -translate-x-10 tablet:translate-x-0",
})``;
const MediaCell10 = styled.div.attrs({
  className: "col-start-5 tablet:col-start-3 col-span-7 tablet:col-span-6 row-start-1 tablet:mt-auto",
})``;
const MediaCell11 = styled.div.attrs({
  className: "col-start-7 col-span-5 tablet:col-start-7 tablet:col-span-4 row-start-2",
})``;
const MediaCell12 = styled.div.attrs({
  className: "col-start-3 tablet:col-start-2 col-span-4 tablet:col-span-5 row-start-2 ml-auto",
})``;

const ImageMediaCell8Wrapper = styled.div.attrs({
  className: "col-start-2 col-span-4 mr-3.5 tablet:mr-0.5 row-start-1 row-span-2",
})``;
const FactMediaCell8Wrapper = styled.div.attrs({
  className: "col-start-6 col-span-3 ml-auto row-start-1 row-span-1",
})``;
