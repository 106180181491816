import React from "react";
import styled from "styled-components";
import { ButtonLinkSolid } from "../../components/button";
import { Headline2, Headline3, Paragraph } from "../../components/typography";
import { Routes } from "../../data/routing";
import { useMatchMedia } from "../../hooks/use-match-media";
import { HomePageContentInterface } from "../../graphql/home-page";
import { theme } from "../../styles/styles";
import BenefitLargeElement from "./benefit-large-element";
import BenefitSmallElement from "./benefit-small-element";
import {
  ActivitiesIcon,
  BenefitsRewardingIcon,
  BenefitsDevelopingIcon,
  BenefitsSupportingIcon,
  BudgetIcon,
  CalendarIcon,
  CountriesIcon,
  DeskIcon,
  CashIcon,
  HomeIcon,
  MetroTrainIcon,
  UmbrellaIcon,
} from "../../images/icons/Icons";
import { createScrollTriggerTimelineScrubReverse, gsapClearProps } from "../../utilities/animation-helper";

interface BenefitSectionProps {
  content: HomePageContentInterface;
}

const BenefitSection: React.FC<BenefitSectionProps> = ({ content }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const match = useMatchMedia("(max-width: 768px)");
  const wrapper = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const topBenefit = React.useRef<HTMLDivElement>(null);
  const bottomFirstBenefit = React.useRef<HTMLDivElement>(null);
  const bottomSecondBenefit = React.useRef<HTMLDivElement>(null);
  const firstRightBenefits = React.useRef<HTMLDivElement>(null);
  const secondRightBenefits = React.useRef<HTMLDivElement>(null);
  const firstLeftBenefits = React.useRef<HTMLDivElement>(null);
  const firstBenefitLarge = React.useRef<HTMLDivElement>(null);
  const secondBenefitLarge = React.useRef<HTMLDivElement>(null);
  const thirdBenefitLarge = React.useRef<HTMLDivElement>(null);
  const moreBenefits = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setIsMobile(match);
  }, [match]);

  React.useEffect(() => {
    gsapClearProps([
      wrapper.current,
      firstBenefitLarge.current,
      secondBenefitLarge.current,
      firstBenefitLarge.current,
      contentRef.current?.children[0],
      contentRef.current?.children[1],
      topBenefit.current,
      firstRightBenefits.current?.children,
      secondBenefitLarge?.current?.children[0],
      thirdBenefitLarge?.current?.children[0],
      firstLeftBenefits?.current?.children,
      secondRightBenefits.current?.children,
      bottomFirstBenefit.current,
      bottomSecondBenefit.current,
      moreBenefits?.current?.children[0],
      moreBenefits?.current?.children[1],
    ]);
    if (isMobile) {
      return;
    }

    const desktopTl = createScrollTriggerTimelineScrubReverse(wrapper.current, {
      start: "-=300 top",
      end: "+=80%",
    });

    const desktopTl2 = createScrollTriggerTimelineScrubReverse(firstBenefitLarge.current, {
      start: "-=500 top",
      end: "+=120%",
    });

    const desktopTl3 = createScrollTriggerTimelineScrubReverse(secondBenefitLarge.current, {
      start: "top top",
      end: "+=70%",
    });

    desktopTl
      .add("start")
      .from(firstBenefitLarge.current, { y: "8rem" }, "start")
      .from(contentRef.current?.children[0], { y: "-2rem" }, "start")
      .from(contentRef.current?.children[1], { y: "3rem" }, "start")
      .from(firstRightBenefits.current?.children, { y: "12rem", stagger: 0.1 }, "start");

    desktopTl2
      .add("start2")
      .from(secondBenefitLarge?.current?.children[0], { y: "4rem" }, "start2")
      .from(thirdBenefitLarge?.current?.children[0], { y: "14rem" }, "start2")
      .from(firstLeftBenefits?.current?.children, { y: "10rem", stagger: 0.1 }, "start2")
      .from(secondRightBenefits.current?.children, { y: "10rem", stagger: 0.1 }, "start2")
      .from(bottomFirstBenefit.current, { y: "16rem" }, "start2")
      .from(bottomSecondBenefit.current, { y: "22rem" }, "start2");

    desktopTl3.from([moreBenefits?.current?.children[0], moreBenefits?.current?.children[1]], {
      y: "10rem",
      stagger: 0.05,
    });

    return () => {
      if (!isMobile) {
        desktopTl.kill();
        desktopTl2.kill();
        desktopTl3.kill();
      }
    };
  }, [isMobile]);

  return (
    <Wrapper ref={wrapper}>
      <WrapperHeadline>
        <ContentWrapper ref={contentRef}>
          <HeadlineWrapper>
            <Headline2>{content.benefitsSectionTitle}</Headline2>
          </HeadlineWrapper>
          <ParagraphWrapper>
            <Paragraph dangerouslySetInnerHTML={{ __html: content.benefitsSectionContent }} />
          </ParagraphWrapper>
        </ContentWrapper>
      </WrapperHeadline>
      <BenefitsWrapper>
        <MediaCell1 ref={firstBenefitLarge}>
          <BenefitLargeElement
            headline="Rewarding you"
            subHeadline="A package that values your skills and expertise."
            icon={BenefitsRewardingIcon}
          />
        </MediaCell1>
        <MediaCell2>
          <RightWrapper ref={topBenefit}>
            <BenefitSmallElement icon={DeskIcon} title="comfortable work space" />
          </RightWrapper>
        </MediaCell2>
        <MediaCell3>
          <BenefitsGridIconsWrapper ref={firstRightBenefits}>
            <LeftWrapper>
              <BenefitSmallElement icon={HomeIcon} title="working in a hybrid model" />
            </LeftWrapper>
            <LeftWrapper>
              <BenefitSmallElement icon={CalendarIcon} title="25 days paid annual leave" />
            </LeftWrapper>
          </BenefitsGridIconsWrapper>
        </MediaCell3>
        <MediaCell4 ref={secondBenefitLarge}>
          <BenefitLargeElement
            headline="Developing you"
            subHeadline="Broaden your knowledge and expand your horizons."
            icon={BenefitsDevelopingIcon}
          />
        </MediaCell4>
        <MediaCell5>
          <BenefitsGridIconsWrapper ref={firstLeftBenefits}>
            <RightWrapper>
              <BenefitSmallElement icon={BudgetIcon} title="personal development budget" />
            </RightWrapper>
            <RightWrapper>
              <BenefitSmallElement icon={CountriesIcon} title="lead projects for over 50 countries" />
            </RightWrapper>
          </BenefitsGridIconsWrapper>
        </MediaCell5>
        <MediaCell6>
          <LeftWrapper ref={bottomFirstBenefit}>
            <BenefitSmallElement icon={ActivitiesIcon} title="regular  work-social activities" />
          </LeftWrapper>
        </MediaCell6>
        <MediaCell7 ref={thirdBenefitLarge}>
          <BenefitLargeElement
            headline="Supporting you"
            subHeadline="A diverse, inclusive and sociable atmosphere."
            icon={BenefitsSupportingIcon}
          />
        </MediaCell7>
        <MediaCell8>
          <BenefitsGridIconsWrapper ref={secondRightBenefits}>
            <LeftWrapper>
              <BenefitSmallElement icon={CashIcon} title="competitive salary and bonus scheme" />
            </LeftWrapper>
            <LeftWrapper>
              <BenefitSmallElement icon={UmbrellaIcon} title="retirement plan fully covered by Miele X " />
            </LeftWrapper>
          </BenefitsGridIconsWrapper>
        </MediaCell8>
        <MediaCell9 ref={bottomSecondBenefit}>
          <BenefitSmallElement icon={MetroTrainIcon} title="NS Business Card and Swap Fiets" />
        </MediaCell9>
        <MediaCell10 ref={moreBenefits}>
          <HeadlineBenefitsWrapper>
            <Headline3 as="div">
              and there’s much <strong>more</strong>…
            </Headline3>
          </HeadlineBenefitsWrapper>
          <ButtonLinkSolid to={Routes.benefits}>Discover your benefits</ButtonLinkSolid>
        </MediaCell10>
      </BenefitsWrapper>
    </Wrapper>
  );
};

export default BenefitSection;

const Wrapper = styled.div.attrs({ className: "scrollspy" })``;
const BenefitsWrapper = styled.div.attrs({
  className: "px-5 grid gap-4 tablet:grid-cols-12 mb-12 tablet:mb-36 tablet:-mt-80",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(10, auto);
  }
`;
const WrapperHeadline = styled.div.attrs({
  className: "tablet:grid tablet:grid-cols-12 tablet:gap-4 px-5 mb-12 tablet:mb-0",
})``;
const ContentWrapper = styled.div.attrs({
  className: "col-start-2 col-span-4 ",
})``;
const HeadlineWrapper = styled.div.attrs({ className: "tablet:mt-16" })``;
const ParagraphWrapper = styled.div.attrs({ className: "tablet:pr-16" })``;
const HeadlineBenefitsWrapper = styled.div.attrs({
  className: "w-3/4 tablet:w-full",
})``;
const RightWrapper = styled.div.attrs({
  className: "col-start-1 col-span-2 w-5/6 ml-auto",
})``;
const LeftWrapper = styled.div.attrs({
  className: "col-start-1 col-span-2 w-5/6 mr-auto",
})``;
const BenefitsGridIconsWrapper = styled.div.attrs({
  className: "grid grid-cols-2 gap-4 ",
})``;
const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-3 tablet:row-start-3 tablet:row-span-4",
})``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:row-start-2 tablet:row-span-1 tablet:col-start-8 tablet:col-span-2 hidden tablet:block",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-10 tablet:col-span-2 tablet:row-start-3 tablet:row-span-4 hidden tablet:block",
})``;
const MediaCell4 = styled.div.attrs({
  className: "tablet:col-start-4 tablet:col-span-3 tablet:row-start-5 tablet:row-span-4 mt-auto",
})``;
const MediaCell5 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-2 tablet:row-start-5 tablet:row-span-4 mt-auto hidden tablet:block",
})``;
const MediaCell6 = styled.div.attrs({
  className: "tablet:col-start-4 tablet:col-span-2 tablet:row-start-9 tablet:row-span-2 hidden tablet:block",
})``;
const MediaCell7 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-3 tablet:row-start-7 tablet:row-span-4",
})``;
const MediaCell8 = styled.div.attrs({
  className: "tablet:col-start-10 tablet:col-span-2 tablet:row-start-7 tablet:row-span-4 mt-auto hidden tablet:block",
})``;
const MediaCell9 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-2 tablet:row-start-11 tablet:row-span-2 hidden tablet:block",
})``;
const MediaCell10 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-3 tablet:row-start-13 tablet:row-span-1 tablet:-mt-10",
})``;
