import React from "react";
import styled from "styled-components";
import { IconInterface } from "../../images/icons/Icons";

interface BenefitSmallProps {
  title: string | React.ReactNode;
  icon: IconInterface;
}

const BenefitSmallElement: React.FC<BenefitSmallProps> = ({ title, icon }) => {
  return (
    <SmallBenefitWrapper>
      <SmallIcoWrapper>
        <Images src={icon.src} alt={icon.alt} />
      </SmallIcoWrapper>
      <SmallTitleWrapper>{title}</SmallTitleWrapper>
    </SmallBenefitWrapper>
  );
};

export default BenefitSmallElement;

const SmallBenefitWrapper = styled.div.attrs({
  className: " pt-4 px-5 pb-8 bg-gray2 rounded-lg",
})``;
const SmallIcoWrapper = styled.div.attrs({ className: "mb-2.5" })``;
const Images = styled.img.attrs({ className: "h-full" })``;
const SmallTitleWrapper = styled.h4.attrs({
  className: "text-white  text-benefit font-bold",
})``;
