import React from "react";
import styled from "styled-components";
import { Hero } from "../components/hero";
import { IconPlay } from "../components/icons";
import BenefitSection from "../sections/home/benefits-section";
import HomeSection from "../sections/home/home-section";
import JobsSection from "../sections/home/jobs-section";
import RecentBlogPostsSection from "../sections/home/recent-blog-posts-section";
import Layout from "../components/layout";
import VideoElementModal from "../components/video-element-modal/video-element-modal";
import { graphql, PageProps } from "gatsby";
import { HomePageContentInterface } from "../graphql/home-page";
import { transformHeroData } from "../utilities/pimcore-data-transformer";
import Scrollspy from "../components/scrollspy/scrollspy";

interface HomePageDataInterface {
  pimcore: {
    content: HomePageContentInterface;
  };
}

const labels = ["Welcome", "Our story", "Our work", "Our team", "Our office", "Jobs", "Your benefits", "News"];

const IndexPage: React.FC<PageProps<HomePageDataInterface>> = ({ data }) => {
  const [showModal, setShowModal] = React.useState(false);
  const content = data.pimcore.content;

  return (
    <Layout
      navCurrentItem="index"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{ title: content.metaOgTitle, description: content.metaOgTitle, image: content.metaOgImage }}
      schemaOrg={content.metaSchema}
    >
      <Header>
        <Hero {...transformHeroData(content)}>
          <Title as="h1" dangerouslySetInnerHTML={{ __html: content.heroTitle || "" }} />
          <PlayVideoButton onClick={() => setShowModal((prev) => !prev)}>
            Watch this video
            <IcoWrapper>
              <IconPlay />
            </IcoWrapper>
          </PlayVideoButton>
        </Hero>
      </Header>
      <Main>
        <HomeSection content={content} />
        <JobsSection content={content} />
        <BenefitSection content={content} />
        <RecentBlogPostsSection content={content} />
        {showModal && (
          <VideoElementModal
            closeModal={() => setShowModal((prev) => !prev)}
            showModal={showModal}
            srcVideo={process.env.GATSBY_PIMCORE_BASE_URL + content.heroFullVideo.data.fullpath}
          />
        )}
      </Main>
      <Scrollspy labels={labels} gap={6} />
    </Layout>
  );
};

const Main = styled.main.attrs({ className: "x-background" })``;

const Header = styled.header.attrs({ className: "h-screen scrollspy" })``;
const Title = styled.h3.attrs({ className: "mb-8 tablet:mb-0" })``;
const PlayVideoButton = styled.button.attrs({
  className:
    "relative text-sm text-white px-14 tablet:px-16 py-4 tablet:py-5 rounded-full backdrop-blur-md bg-black/[.23] border-2 border-white/[.49] backdrop-brightness-100 hover:backdrop-brightness-125 hover:brightness-125	",
})`
  @supports (-moz-appearance: none) {
    background-color: rgba(0, 0, 0, 0.23);

    :hover {
      filter: brightness(1.25)
    }
  }
`;
const IcoWrapper = styled.div.attrs({
  className: "w-3 h-4 absolute top-1/2 right-4 tablet:right-6 -translate-y-1/2",
})``;

export const query = graphql`
  query {
    pimcore {
      ...homePageData
    }
  }
`;

export default IndexPage;
