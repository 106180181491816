import * as React from "react";
import styled from "styled-components";
import { Headline2, Paragraph } from "../../components/typography";
import { ButtonLinkSolid } from "../../components/button";
import { rem, theme } from "../../styles/styles";
import { useMatchMedia } from "../../hooks/use-match-media";
import { PimcoreImage } from "../../components/pimcore-image";
import { ImageDataInterface } from "../../graphql/common";
import { createTimeline, gsapSet } from "../../utilities/animation-helper";

interface HomeSectionProps extends ContentWrapperHomeSection {
  background: ImageDataInterface;
  backgroundMobile?: ImageDataInterface;
  headline: string;
  content: string;
  buttonTo: string;
  buttonLabel: string;
  modifierParagraph?: string;
}
interface ContentWrapperHomeSection {
  align?: string;
}

interface ParagrphModifire {
  modifier?: string;
}

const HomeSectionElement: React.FC<HomeSectionProps> = ({
  background,
  backgroundMobile,
  modifierParagraph,
  headline,
  content,
  buttonTo,
  buttonLabel,
  children,
  align = "",
}) => {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const imageRef = React.useRef<HTMLDivElement | null>(null);
  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const tlImg = React.useRef<GSAPTimeline>();
  const tlContent = React.useRef<GSAPTimeline>();
  const match = useMatchMedia("(max-width: 767.8px)");
  const [isMobile, setIsMobile] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsMobile(match);
  }, [match]);

  React.useEffect(() => {
    gsapSet([wrapperRef.current, imageRef.current, contentRef.current?.children], { clearProps: true });

    if (isMobile) {
      return;
    }

    gsapSet([contentRef.current?.children], { autoAlpha: 1 });

    tlImg.current = createTimeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: "top top",
        toggleActions: "restart none none reverse",
      },
    });

    tlContent.current = createTimeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: "top bottom",
        end: "bottom-=200rem top",
        scrub: 1,
        toggleActions: "restart none none reverse",
      },
    });

    tlContent.current.from([contentRef.current?.children], {
      y: "+=8rem",
      stagger: 0.1,
      autoAlpha: 1,
    });

    tlImg.current.to(imageRef.current, {
      scale: 0.95,
      height: "calc(100vh - 4rem)",
      borderRadius: rem(8),
      duration: 1,
    });

    return () => {
      tlImg.current?.scrollTrigger?.kill();
      tlContent.current?.scrollTrigger?.kill();
      tlImg?.current?.kill();
      tlContent?.current?.kill();
    };
  }, [isMobile]);

  return (
    <Wrapper ref={wrapperRef}>
      <ImageWrapper ref={imageRef}>
        <PimcoreImage
          image={background}
          mobileImage={backgroundMobile}
          modifier="w-full h-full object-cover"
          withAspectRatio
        />
      </ImageWrapper>
      <ContentWrapper align={align} ref={contentRef}>
        <HeadlineWrapper>
          <Headline2>{headline}</Headline2>
        </HeadlineWrapper>
        <ParagraphWrapper modifier={modifierParagraph}>
          <Paragraph>{content}</Paragraph>
        </ParagraphWrapper>
        <ButtonWrapper>
          <ButtonLinkSolid to={buttonTo}>{buttonLabel}</ButtonLinkSolid>
        </ButtonWrapper>
      </ContentWrapper>
      {children}
    </Wrapper>
  );
};
export default HomeSectionElement;

const Wrapper = styled.div.attrs({
  className: "relative tablet:grid tablet:grid-cols-12 gap-4 mb-20 tablet:mb-56 px-5 tablet:h-screen tablet:w-full ",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(7, auto);
  }
`;
const Image = styled.img.attrs({ className: "w-full rounded-lg m-0" })``;
const ImageWrapper = styled.div.attrs({
  className:
    "tablet:absolute tablet:top-1/2 tablet:-translate-y-1/2 tablet:left-1/2 tablet:-translate-x-1/2 tablet:w-full tablet:h-screen overflow-hidden rounded-lg tablet:rounded-none",
})``;
const HeadlineWrapper = styled.div.attrs({
  className: "",
})``;
const ParagraphWrapper = styled.div.attrs<ParagrphModifire>(({ modifier }) => ({
  className: `${modifier && modifier} mb-8`,
}))<ParagrphModifire>``;
const ButtonWrapper = styled.div.attrs({
  className: "",
})``;

const ContentWrapper = styled.div.attrs<ContentWrapperHomeSection>(({ align }) => ({
  className: ` ${
    align === "left" ? "tablet:col-start-2" : "tablet:col-start-8"
  } col-span-5 row-start-1 row-span-4  tablet:mt-32 absolute inset-x-6 top-36 tablet:inset-x-0 tablet:top-0 tablet:relative px-5 tablet:px-0`,
}))<ContentWrapperHomeSection>``;
