import React from "react";
import styled from "styled-components";
import { Headline2, Paragraph } from "../typography";
import { JobOfferInterface } from "../../graphql/jobs";
import { JobOfferTile } from ".";
import { JobOfferDataInterface } from "../apply-job/apply-job-modal";
import { ApplyJobModal } from "..//apply-job";
import HorizontalSlider from "../horizontal-slider/horizontal-slider";
import { useMatchMedia } from "../../hooks/use-match-media";

interface JobSectionProps {
  headline: string;
  description: string;
  jobOffers: Array<JobOfferInterface>;
}

const CategoryJobOffers: React.FC<JobSectionProps> = ({ headline, description, jobOffers }) => {
  const match = useMatchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = React.useState(false);
  const [currentOffer, setCurrentOffer] = React.useState<JobOfferDataInterface | undefined>();
  const ListWrapper = isMobile ? HorizontalSlider : List;

  React.useEffect(() => {
    setIsMobile(match);
  }, [match]);

  const onApply = (offerId: string, jobTitle: string) => setCurrentOffer({ offerId, jobTitle });

  return (
    <Wrapper>
      <Header>
        <Headline2 dangerouslySetInnerHTML={{ __html: headline }} />
        <Paragraph as="div" dangerouslySetInnerHTML={{ __html: description }} />
      </Header>
      <ListWrapper>
        {jobOffers.map((jobOffer, index) => (
          <JobOfferTile key={`job_offer_${index}`} jobOffer={jobOffer} onApply={onApply} size="fixed" />
        ))}
      </ListWrapper>
      <ApplyJobModal offerData={currentOffer} onClose={() => setCurrentOffer(undefined)} />
    </Wrapper>
  );
};

export default CategoryJobOffers;

const Wrapper = styled.div.attrs({
  className: "w-full bg-gray2 tablet:grid tablet:grid-cols-12 gap-4 tablet:pb-36 tablet:px-5 scrollspy",
})``;

const Header = styled.div.attrs({
  className: "px-5 tablet:col-start-4 tablet:col-span-6 pt-14 pb-12 tablet:py-24 tablet:text-center",
})``;
const List = styled.div.attrs({
  className: "grid gap-4 tablet:col-start-2 tablet:col-span-10 tablet:grid-cols-3",
})``;
